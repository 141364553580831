import { Component, OnInit } from '@angular/core';
import {TitleHeaderService} from "../title-header/title-header.service";
import {map, share, tap} from "rxjs/operators";
import {SimpleAverages} from "../model/monthly-averages.model";
import {HttpService} from "../http/http.service";
import {DailyAverages} from "../model/daily-averages.model";
import {Observable} from "rxjs";
import {DailyExtremes} from "../model/daily-extremes.model";
import {SimpleExtremes} from "../model/simple-extremes.model";
import {TimestampedMeasurement} from "../model/timestamped-measurement.model";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {

  month: number;
  monthName: string;
  year: number;

  dailyAverages: DailyAverages[];
  dailyExtremes: DailyExtremes[];
  monthlyAverages: SimpleAverages;
  monthlyExtremes: SimpleExtremes;
  yearlyAverages: Observable<SimpleAverages>;
  yearlyExtremes: Observable<SimpleExtremes>;
  monthlySum: Observable<number>;
  yearlySum: Observable<number>;


  loadedExtremes = false;
  loadedAverages = false;

  constructor(private titleHeaderService: TitleHeaderService,
              private httpService: HttpService,
              private datePipe: DatePipe) { }

  ngOnInit() {
    this.titleHeaderService.setTitle("Statistieken")
    const now = new Date();
    // this.month = now.getDate() > 1 ? (now.getMonth() + 1) : (now.getMonth() === 0 ? 11 : now.getMonth());
    this.month = now.getMonth() + 1;
    this.monthName = this.datePipe.transform(now, 'MMMM', 'CET', 'nl-NL');
    // this.year = (now.getMonth() === 0 && now.getDate() === 1) ? now.getFullYear() - 1 : now.getFullYear();
    this.year = now.getFullYear();

    this.loadMonthBasedData();
    this.loadYearBasedData();
    this.loadRainSums();
  }

  loadRainSums() {
    const $rainSums = this.httpService.getRainSums(this.year, this.month).pipe(share());
    this.monthlySum = $rainSums.pipe(
      map((r) => r.monthlySum.sum)
    );
    this.yearlySum = $rainSums.pipe(
      map((r) => r.yearlySum.sum)
    );
  }

  loadYearBasedData() {
    this.yearlyAverages = this.httpService.yearlyAverages(this.year);
    this.yearlyExtremes = this.httpService.yearlyExtremes(this.year);
  }

  loadMonthBasedData() {
    this.loadedExtremes = false;
    this.loadedAverages = false;
    this.httpService.averages(this.year, this.month).subscribe((avg) => {
      this.monthlyAverages = avg.monthlyAverages;
      this.dailyAverages = avg.dailyAverages.reverse();
      this.loadedAverages = true;
    })
    this.httpService.dailyExtremes(this.month, this.year)
      .subscribe(dex => {
        this.dailyExtremes = dex.reverse();

        if (dex.length > 0) {
          let mex: SimpleExtremes = new SimpleExtremes();
          mex.maxTemperature = this.maxByKey('maxTemperature', dex);
          mex.minTemperature = this.minByKey('minTemperature', dex);
          mex.minHumidity = this.minByKey('minHumidity', dex)
          mex.maxHumidity = this.maxByKey('maxHumidity', dex);
          mex.maxPressure = this.maxByKey('maxPressure', dex);
          mex.minPressure = this.minByKey('minPressure', dex);
          mex.maxSolarRadiation = this.maxByKey('maxSolarRadiation', dex);
          mex.maxWindSpeed = this.maxByKey('maxWindSpeed', dex);
          this.monthlyExtremes = mex;
        }
        this.loadedExtremes = true;
      });
  }

  maxByKey(key: string, dex: DailyExtremes[]): TimestampedMeasurement<number> {
    return dex.map(x => x[key])
      .filter(v => !!v)
      .reduce((a, b) => a.value > b.value ? a : b);
  }

  minByKey(key: string, dex: DailyExtremes[]): TimestampedMeasurement<number> {
    return dex.map(x => x[key])
      .filter(v => !!v)
      .reduce((a, b) => a.value < b.value ? a : b);
  }

  setMonth($event) {
    this.month = $event['month'];
    this.monthName = $event['monthName'];
    this.loadMonthBasedData();
    this.loadRainSums();
  }

  setYear(year: number) {
    this.year = year;
    this.loadYearBasedData();
    this.loadRainSums();
  }

}
