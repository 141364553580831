import {Component, OnInit} from '@angular/core';
import {ChartData} from "chart.js";
import {ChartModel} from "../model/chart-model.model";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {HttpService} from "../http/http.service";
import {map, tap} from "rxjs/operators";

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss']
})
export class AnalysisComponent implements OnInit {

  tempData: Observable<ChartModel>;
  data: Observable<ChartModel>[] = [];
  constructor(private httpService: HttpService) {

  }

  ngOnInit(): void {
    this.tempData = this.httpService.getTemperatureAnalysisData('2024-08-19', '19:00', '2024-08-19', '19:35')
      .pipe(tap(cm => {
        cm.options = {spanGaps: true};
        cm.data.forEach(d => {
          // if (d.title === 'PT100_MAX_19216861_D3' && d.title.startsWith("PT100")) {
          //   d.values  = d.values.map(v => v === null ? null : v + -0.03);
          // }
          d.pointRadius = 0.9;
          d.lineWidth = 0.8;
        })
      }));

    this.data.push(this.tempData);
  }



}
