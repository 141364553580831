import {Component, OnDestroy, OnInit} from '@angular/core';
import {ChartModel} from "../model/chart-model.model";
import {Observable} from "rxjs";
import {HttpService} from "../http/http.service";
import {map, share, tap} from "rxjs/operators";
import {ChartValues} from "../model/chart-values.model";
import {SensorSnapshotWrapper} from "../model/sensor-snapshot-wrapper.model";
import {TitleHeaderService} from "../title-header/title-header.service";
import {FormControl, UntypedFormControl} from "@angular/forms";
import {Sensor} from "../model/sensor.model";
import {ActivatedRoute, Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {DataElement} from "../model/reading.model";
import {Chart} from "chart.js";

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnInit, OnDestroy {

  temperatureData: Observable<ChartModel>;
  temperatureReferenceData: Observable<ChartModel>;
  humidityData: Observable<ChartModel>;
  pressureData: Observable<ChartModel>;
  windDirData: Observable<ChartModel>;
  windSpeedData: Observable<ChartModel>;
  radiationData: Observable<ChartModel>;
  airQualityData: Observable<ChartModel>;

  precipitationData: Observable<any>;

  fanRpmData: Observable<ChartModel>

  rawData: Observable<SensorSnapshotWrapper>;
  shownData: Observable<ChartModel>;

  shownId: string = 'temp';
  loading = false;
  showFanRpm = false;
  queryingApi = false;
  lastSnapShotLoad: Date;
  lastPrecipitationLoad: Date;
  autoReload = true;
  reloadSpinnerValue: number;
  tempReferenceSensor: FormControl<number> = new FormControl();
  startDate = new UntypedFormControl(new Date());
  endDate = new UntypedFormControl(new Date());
  maxDate = new Date();
  minDate = new Date(2020, 8, 8);
  selectedStartDate: string;
  selectedEndDate: string;
  startTime: string;
  endTime: string;
  loadedEndDate: Date;
  colors = ['#1ab0de', '#6e9e1a', '#4c64ea', '#bba619', '#44CBAC'];
  snapShotLoadTimer: any;
  precipitationLoadTimer: any;

  dateOptions: Date[];

  constructor(private httpService: HttpService,
              private titleHeaderService: TitleHeaderService,
              private route: ActivatedRoute,
              private router: Router,
              private datePipe: DatePipe) {
  }

  private parseQueryParams() {
    let graph = this.route.snapshot.queryParamMap.get('graph');
    if (graph) {
      this.shownId = graph;
    }

    let startDate = this.route.snapshot.queryParamMap.get('startDate');
    let endDate = this.route.snapshot.queryParamMap.get('endDate');
    let startTime = this.route.snapshot.queryParamMap.get('startTime');
    let endTime = this.route.snapshot.queryParamMap.get('endTime');
    if (this.validParams(startDate, endDate, startTime, endTime)) {
      this.startTime = startTime;
      this.endTime = endTime;
      this.startDate.setValue(new Date(startDate));
      this.endDate.setValue(new Date(endDate));
      this.autoReload = false;
    }
  }

  private validParams(startDate: string, endDate: string, startTime: string, endTime: string): boolean {
    let allPresent = !!startDate && !!endDate && !!startTime && !!endTime;
    let timeValid = parseInt(startTime?.replace(':', '')) < parseInt(endTime?.replace(':', ''));

    let startDateObj = new Date(startDate);
    let endDateObj = new Date(endDate);

    let dateValid = endDateObj >= startDateObj && (endDateObj.getTime() - startDateObj.getTime()) <= 86_400_000;
    return allPresent && timeValid && dateValid;
  }

  private setQueryParam(key: string, value: string) {
    let qp = `{"${key}": "${value}"}`;
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: JSON.parse(qp),
        queryParamsHandling: 'merge',
      });
  }

  ngOnInit() {
    this.titleHeaderService.setTitle("Grafieken");
    this.parseQueryParams();
    const now = new Date();
    let dateOptions = [];
    for (let i = 1; i < 7; i++) {
      dateOptions.push(new Date(now.getTime() - (i * 86_400_000)));
    }
    this.dateOptions = dateOptions;
    this.initDateTimePicker();
    this.initializeDataSet();

    this.snapShotLoadTimer = setInterval(() => {
      const now = new Date();
      if (this.shownId !== 'precipitation' &&
        !this.queryingApi &&
        (!this.lastSnapShotLoad || this.autoReload && now.getTime() - this.lastSnapShotLoad.getTime() >= 60000)) {
        let start = new Date(this.startDate.value.getTime() + 60_000); // increment start with 1 minute
        this.startTime = this.dateToTime(start);
        this.startDate.setValue(start);
        this.loadRawData();
        this.processData();
        this.endTime = this.dateToTime(now);
      }
      this.reloadSpinnerValue = ((((this.lastSnapShotLoad?.getTime() + 60000) - now.getTime()) / 60) * 100.0) / 1000.0;
    }, 1000);

    this.precipitationLoadTimer = setInterval(() => {
      const now = new Date();
      if (this.shownId === 'precipitation' &&
        !this.queryingApi && this.autoReload &&
        (!this.lastPrecipitationLoad || now.getTime() - this.lastPrecipitationLoad.getTime() >= 60000)) {
        let start = new Date(this.startDate.value.getTime() + 60_000); // increment start with 1 minute
        this.startTime = this.dateToTime(start);
        this.startDate.setValue(start);
        this.loadRawData();
        this.processData();
        this.endTime = this.dateToTime(now);
      }
      this.reloadSpinnerValue = ((((this.lastSnapShotLoad?.getTime() + 60000) - now.getTime()) / 60) * 100.0) / 1000.0;
    }, 1000);


    this.startDate.valueChanges.subscribe((d: Date) => {
      this.selectedStartDate = this.dateToLocalDate(d);
      // const now = new Date();
      // if (!this.isSameDay(now, d)) {
      //   this.autoReload = false;
      // }
    });
    this.endDate.valueChanges.subscribe((d: Date) => {
      this.selectedEndDate = this.dateToLocalDate(d);

      if (this.isSameDay(d, now)) {
        this.endTime = this.dateToTime(now);
      } else {
        this.autoReload = false;
      }
    })

    this.tempReferenceSensor.valueChanges.subscribe(tempRefSensor => {
      console.log('Temp reference changed');
      this.extractTemperatureReferenceData();
    })
  }

  canReload(): boolean {
    return this.within24Hours() && this.startBeforeEnd();
  }

  private within24Hours(): boolean {
    const diff = this.fullEndDateTime().getTime() - this.fullStartDateTime().getTime();
    return diff < (86_400_000 + 60_000); // 1 day + 1 minute margin
  }

  private startBeforeEnd(): boolean {
    return this.fullDateTime(this.startDate.value, this.startTime) < this.fullDateTime(this.endDate.value, this.endTime);
  }

  fullEndDateTime(): Date {
    return this.fullDateTime(this.endDate.value, this.endTime);
  }

  fullStartDateTime(): Date {
    return this.fullDateTime(this.startDate.value, this.startTime)
  }

  fullDateTime(date: Date, time: string): Date {
    const copy = new Date(date);
    const hour = +time.split(":")[0];
    const minute = +time.split(":")[1];
    copy.setHours(hour);
    copy.setMinutes(minute);
    copy.setSeconds(0);
    return copy;
  }

  dateToTime(date: Date): string {
    const hour = date.getHours() >= 10 ? date.getHours() : '0' + date.getHours();
    const minute = date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes();
    return hour + ':' + minute;
  }

  dateToLocalDate(date: Date): string {
    const year = date.getFullYear();
    const monthNo = date.getMonth() + 1;
    const month = monthNo >= 10 ? monthNo : '0' + monthNo;
    const day = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate();
    return year + '-' + month + '-' + day;
  }

  isSameDay(first: Date, second: Date): boolean {
    return first.getDate() === second.getDate() &&
      first.getMonth() === second.getMonth() &&
      first.getFullYear() === second.getFullYear();
  }

  toggleLiveUpdate($event) {
    if ($event) {
      this.initDateTimePicker();
      if ((new Date().getTime() - this.loadedEndDate.getTime()) > 60_000) {
        this.loadRawData();
        this.processData();
      }
    }
  }

  changeHours(hours: number) {
    const now = new Date();
    const start = new Date(now.getTime() - (hours * 3_600_000));

    this.endTime = this.dateToTime(now);
    this.startTime = this.dateToTime(start);
    this.startDate.setValue(start);
    this.endDate.setValue(now, {emitEvent: false});

    this.loadRawData();
    this.processData();
  }

  setFullDate(date: Date) {
    const start = new Date(date);
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    const end = new Date(start.getTime() + 86_400_000);

    this.startDate.setValue(start);
    this.endDate.setValue(end);
    this.endTime = this.dateToTime(end);
    this.startTime = this.dateToTime(start);

    this.loadRawData();
    this.processData();
  }

  initializeDataSet() {
    this.loading = true;
    this.loadRawData();
    this.processData();
    this.setShownData(this.shownId);
  }

  initDateTimePicker() {
    if (!this.startDate.value || !this.endDate.value || !this.startTime || !this.endTime) {
      const now = new Date();
      const start = new Date();
      start.setHours(start.getHours() - 2);
      this.endTime = this.dateToTime(now);
      this.startTime = this.dateToTime(start);
      this.startDate.setValue(start);
      this.endDate.setValue(now);
    }
    this.selectedStartDate = this.dateToLocalDate(this.startDate.value);
    this.selectedEndDate = this.dateToLocalDate(this.endDate.value);

  }

  loadRawData() {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {
          endTime: this.endTime,
          startTime: this.startTime,
          endDate: this.selectedEndDate,
          startDate: this.selectedStartDate,
          graph: this.shownId
        },
        queryParamsHandling: 'merge',
      });
    this.queryingApi = true;

    if (this.shownId !== 'precipitation') {
      this.rawData = this.getSnapshotsBetween()
    }
    if (this.shownId === 'precipitation') {
      this.precipitationData = this.getPrecipitationBetween()
    }

    this.getFanRpmDataBetween();

  }

  getPrecipitationBetween(): Observable<any> {
    console.log('Loading precipitation data')
    const startTime = this.startTime + ':00';
    const endTime = this.endTime + ':00';
    this.queryingApi = true;
    return this.httpService.precipitationChartDataBetween(this.selectedStartDate, startTime, this.selectedEndDate, endTime).pipe(
      tap(() => {
        this.queryingApi = false;
        this.lastPrecipitationLoad = new Date();
        this.loadedEndDate = this.fullEndDateTime();
      }),
      share()
    );
  }

  getSnapshotsBetween(): Observable<SensorSnapshotWrapper> {
    console.log('Loading snapshots')
    const startTime = this.startTime + ':00';
    const endTime = this.endTime + ':00';
    this.queryingApi = true;
    return this.httpService.sensorSnapshotsBetween(this.selectedStartDate, startTime, this.selectedEndDate, endTime).pipe(
      tap(() => {
        this.queryingApi = false;
        this.lastSnapShotLoad = new Date();
        this.loadedEndDate = this.fullEndDateTime();
      }),
      share()
    );
  }

  getFanRpmDataBetween() {
    const startTime = this.startTime + ':00';
    const endTime = this.endTime + ':00';
    this.fanRpmData = this.httpService.fanRpmDataBetween(this.selectedStartDate, startTime, this.selectedEndDate, endTime)
      .pipe(tap(cv => {
        cv.data.forEach(d => {
          if (d.title === 'barani_FARS') {
            d.title = 'Barani FARS';
            d.color = this.colors[1]
          } else if (d.title === 'ts100') {
            d.title = 'Apogee TS-100';
            d.color = this.colors[0];
          }
        })
      }));
  }

  setDataToShow(id: string) {
    this.loading = true;
    this.shownId = id;
    this.setQueryParam('graph', id);
    this.setShownData(id);
  }

  setShownData(id: string) {
    switch (id) {
      case 'temp' :
        this.shownData = this.temperatureData;
        break;
      case 'tempref' :
        this.shownData = this.temperatureReferenceData;
        break;
      case 'wind' :
        this.shownData = this.windSpeedData;
        break;
      case 'winddir' :
        this.shownData = this.windDirData;
        break;
      case 'pressure' :
        this.shownData = this.pressureData;
        break;
      case 'humidity' :
        this.shownData = this.humidityData;
        break;
      case 'radiation' :
        this.shownData = this.radiationData;
        break;
      case 'airquality' :
        this.shownData = this.airQualityData;
        break;
      case 'precipitation' :
        this.shownData = undefined;
    }
  }

  /**
   * Only supports dataKey 'temperature' right now
   */
  extractWithReference(title: string, subtitle: string, unit: string, dataKey: 'temperature', sensorKey: string, configuration = {}, options = {}) {
    return this.rawData.pipe(
      map((raw: SensorSnapshotWrapper) => {
        let cm = new ChartModel();
        cm.title = title;
        cm.subtitle = subtitle;
        cm.unit = unit;
        cm.sensors = raw[sensorKey];
        cm.labels = raw.sensorSnapshots.map(s => s.timestamp);
        cm.options = options;

        const refSensorId = this.tempReferenceSensor.pristine ? cm.sensors.find(s => s.primarySensor).id : this.tempReferenceSensor.value;

        let values: DataElement<number>[][] = raw.sensorSnapshots.map(s => s[dataKey]);
        console.log(values);
        console.log('Ref sensor id: ' + refSensorId);
        const refSensorData = values.map(v => v.find(d => d.sensorEntityId === refSensorId));
        console.log(refSensorData);
        cm.data = raw[sensorKey].map((sensor: Sensor) => {
          let cv = new ChartValues();
          cv.title = sensor.description;
          cv.sensorId = sensor.id;
          cv.hidden = sensor.hidden;
          if (sensor.testMode) {
            cv.borderDash = [2, 2];
          }
          cv.showLine = configuration[dataKey]?.showLine != null ? configuration[dataKey]['showLine'] : true;
          cv.pointRadius = configuration[dataKey]?.pointRadius ? configuration[dataKey]['pointRadius'] : 0;
          cv.color = sensor.color;


          cv.values = values
            .map(d => d.find(t => t.sensorEntityId === sensor.id))
            .map((d, index) => {
              if (!!d && !!refSensorData[index]) {
                return d.value - refSensorData[index].value;
              } else {
                return null;
              }
            });
          return cv;
        })
        return cm;
      })
    )

  }

  extract(title: string, subtitle: string, unit: string, dataKeys: string[], sensorKey: string, configuration = {}, options = {}): Observable<ChartModel> {
    return this.rawData.pipe(
      map(raw => {
        let cm = new ChartModel();
        cm.title = title;
        cm.subtitle = subtitle;
        cm.unit = unit;
        cm.sensors = raw[sensorKey];
        cm.labels = raw.sensorSnapshots.map(s => s.timestamp);
        cm.options = options;
        // loop over data keys, inner loop over each sensor
        cm.data = dataKeys.flatMap(dk => {
          let values = raw.sensorSnapshots.map(s => s[dk]);
          return raw[sensorKey].map((sensor: Sensor) => {
            const sensorIndex = raw[sensorKey].findIndex(s => s.id === sensor.id);
            let cv = new ChartValues();
            cv.title = sensor.description;
            cv.sensorId = sensor.id;
            if (dataKeys.length > 1) {
              let subtitle = values
                .map(d => d.find(t => t.sensorEntityId === sensor.id))[0].name;
              cv.title = cv.title + '(' + subtitle + ')';
            }
            cv.hidden = sensor.hidden;
            if (sensor.testMode) {
              cv.borderDash = [2, 2];
            }

            cv.showLine = configuration[dk]?.showLine != null ? configuration[dk]['showLine'] : true;
            cv.pointRadius = configuration[dk]?.pointRadius ? configuration[dk]['pointRadius'] : 0;
            const dataKeyIndex = dataKeys.findIndex(d => d === dk);

            cv.color = sensor.color;

            if (dataKeyIndex === 0 || sensorKey === 'distinctWindSensors') {
              // there is only 1 data key for this sensor, or this is the first of a set of data keys or this is wind data (special condition)
              cv.color = sensor.color;
            } else {
              // other data keys, use a predefined color, add sensorIndex to avoid duplicate colors in case of 2 sensors (wind)
              // this doesn't cover all cases (2 sensors with both more than 2 data indexes)
              cv.color = this.colors[(dataKeyIndex - 1) + sensorIndex];
            }

            cv.values = values
              .map(d => d.find(t => t.sensorEntityId === sensor.id))
              .map(d => d !== undefined ? d.value : null);
            return cv;
          })
        });
        return cm;
      })
    )
  }

  extractTemperatureReferenceData() {
    this.loading = true;
    let start = this.datePipe.transform(this.fullDateTime(this.startDate.value, this.startTime), 'EEE dd-MM yyyy HH:mm', 'CET', 'NL-nl');
    let end = this.datePipe.transform(this.fullDateTime(this.endDate.value, this.endTime), 'EEE dd-MM yyyy HH:mm', 'CET', 'NL-nl');
    let subtitle = `meteodrenthe.nl - weerstation Lieving - ${start} - ${end}`;
    this.temperatureReferenceData = this.extractWithReference('Temperatuur met referentie', subtitle, '°C',
      'temperature', 'distinctTemperatureSensors', {}, {
        scales: {
          y: {
            suggestedMin: -0.3,
            suggestedMax: 0.3
          }
        }}
      );

    this.shownData = this.temperatureReferenceData;
  }

  processData() {
    let start = this.datePipe.transform(this.fullDateTime(this.startDate.value, this.startTime), 'EEE dd-MM yyyy HH:mm', 'CET', 'NL-nl');
    let end = this.datePipe.transform(this.fullDateTime(this.endDate.value, this.endTime), 'EEE dd-MM yyyy HH:mm', 'CET', 'NL-nl');
    let subtitle = `meteodrenthe.nl - weerstation Lieving - ${start} - ${end}`;
    this.temperatureData = this.extract('Temperatuur', subtitle, '°C',
      ['temperature'], 'distinctTemperatureSensors');
    this.extractTemperatureReferenceData();
    this.windSpeedData = this.extract('Wind', subtitle,
      'm/s', ['wind', 'gusts'], 'distinctWindSensors', {gusts: {pointRadius: 1, showLine: false}});
    this.windDirData = this.extract('Windrichting', subtitle,
      '°', ['windDirection'], 'distinctWindSensors', {windDirection: {pointRadius: 1, showLine: false}}, {
        scales: {
          y: {
            max: 360,
            min: 0,
            ticks: {
              stepSize: 45,
              callback: function (value) {
                switch (value) {
                  case 0:
                    return 'N';
                  case 45:
                    return 'NO';
                  case 90:
                    return 'O';
                  case 135:
                    return 'ZO';
                  case 180:
                    return 'Z';
                  case 225:
                    return 'ZW';
                  case 270:
                    return 'W';
                  case 315:
                    return 'NW';
                  case 360:
                    return 'N';
                }
              }
            },
          }
        }
      });
    this.radiationData = this.extract('Zonnestraling', subtitle,
      'W/m2', ['solar'], 'distinctSolarSensors');
    this.pressureData = this.extract('Luchtdruk', subtitle,
      'hPa', ['pressure'], 'distinctPressureSensors');
    this.humidityData = this.extract('Luchtvochtigheid', subtitle,
      '%', ['humidity'], 'distinctTemperatureSensors');
    this.airQualityData = this.extract('Luchtkwaliteit', subtitle, 'μg/m³',
      ['pm1', 'pm2p5', 'pm10'], 'distinctAirQualitySensors')
    this.setShownData(this.shownId);
  }

  loadingEvent(loading: boolean) {
    console.log('set loading: ' + loading);
    this.loading = loading;
  }

  ngOnDestroy(): void {
    if (this.snapShotLoadTimer) {
      clearInterval(this.snapShotLoadTimer);
    }
    if (this.precipitationLoadTimer) {
      clearInterval(this.precipitationLoadTimer);
    }
  }
}

export interface ChartOptions {
  dataKey: string;
  color?: string;
  showLine?: boolean;
  pointRadius?: number;
  title?: string;
}
