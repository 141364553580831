import {ChartValues} from "./chart-values.model";
import {Sensor} from "./sensor.model";

export class ChartModel {
  title: string;
  subtitle?: string;
  labels: Date[];
  dateFormat: string;
  timeUnit: 'hour' | 'day';
  data: ChartValues[];
  unit: string;
  options? = {};
  sensors: Sensor[];
}
