import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {FullWeather} from "../model/full-weather.model";
import {Forecast} from "../model/forecast.model";
import {DailyExtremes} from "../model/daily-extremes.model";
import {SensorSnapshotWrapper} from "../model/sensor-snapshot-wrapper.model";
import {Averages} from "../model/averages.model";
import {SimpleAverages} from "../model/monthly-averages.model";
import {SimpleExtremes} from "../model/simple-extremes.model";
import {YouTubeVideo} from "../model/youtube-video.model";
import {RainSums} from "../model/rain-sums.model";
import {SensorStatus} from "../model/sensor-status.model";
import {ChartModel} from "../model/chart-model.model";
import {ChartModelWrapper} from "../model/chart-model-wrapper.model";

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  static weatherPiUrl = 'https://api.meteodrenthe.nl';
  // weatherPiUrl = 'http://localhost:8083'

  constructor(private httpClient: HttpClient) { }

  fullWeather(): Observable<FullWeather> {
    return this.httpClient.get<FullWeather>(HttpService.weatherPiUrl + '/api/weather/current');
  }

  getForecast(): Observable<Forecast[]> {
    return this.httpClient.get<Forecast[]>(HttpService.weatherPiUrl + '/api/weather/wunderground/forecast');
  }

  dailyExtremes(month: number, year: number): Observable<DailyExtremes[]> {
    return this.httpClient.get<DailyExtremes[]>(HttpService.weatherPiUrl + `/api/weather/extremes/${year}/${month}`);
  }

  yearlyExtremes(year: number): Observable<SimpleExtremes> {
    return this.httpClient.get<SimpleExtremes>(HttpService.weatherPiUrl + `/api/weather/extremes/${year}`);
  }

  averages(year: number, month: number): Observable<Averages> {
    return this.httpClient.get<Averages>(HttpService.weatherPiUrl + `/api/weather/averages/${year}/${month}`);
  }

  yearlyAverages(year: number): Observable<SimpleAverages> {
    return this.httpClient.get<SimpleAverages>(HttpService.weatherPiUrl + `/api/weather/averages/${year}`);
  }

  sensorSnapshots(hours: number): Observable<SensorSnapshotWrapper> {
    return this.httpClient.get<SensorSnapshotWrapper>(HttpService.weatherPiUrl + `/api/weather/snapshots/hours/?hours=${hours}`);
  }

  sensorSnapshotsBetween(startDate: string, timeStart: string, endDate: string, timeEnd: string): Observable<SensorSnapshotWrapper> {
    return this.httpClient.get<SensorSnapshotWrapper>(HttpService.weatherPiUrl +
      `/api/weather/snapshots/between/?startDate=${startDate}&timeStart=${timeStart}&endDate=${endDate}&timeEnd=${timeEnd}`);
  }

  fanRpmDataBetween(startDate: string, timeStart: string, endDate: string, timeEnd: string): Observable<ChartModel> {
    return this.httpClient.get<ChartModel>(HttpService.weatherPiUrl +
      `/api/weather/fanspeed/between/?startDate=${startDate}&timeStart=${timeStart}&endDate=${endDate}&timeEnd=${timeEnd}`);
  }

  precipitationChartDataBetween(startDate: string, timeStart: string, endDate: string, timeEnd: string): Observable<any> {
    return this.httpClient.get<any>(HttpService.weatherPiUrl +
      `/api/weather/chartdata/precipitation/between/?startDate=${startDate}&timeStart=${timeStart}&endDate=${endDate}&timeEnd=${timeEnd}`);
  }

  youTubeVideos(year: number, month: number): Observable<YouTubeVideo[]> {
    return this.httpClient.get<YouTubeVideo[]>(HttpService.weatherPiUrl + `/api/weather/weathercam/videos?year=${year}&month=${month}`);
  }

  latestWeatherCamVideo(): Observable<any> {
    return this.httpClient.get(HttpService.weatherPiUrl + '/api/weather/weathercam/videos/latest');
  }

  getRainSums(year: number, month: number): Observable<RainSums> {
    return this.httpClient.get<RainSums>(HttpService.weatherPiUrl + `/api/weather/rain/${year}/${month}`);
  }

  getSensorStatuses(): Observable<SensorStatus[]> {
    return this.httpClient.get<SensorStatus[]>(HttpService.weatherPiUrl + '/api/weather/sensors');
  }

  getLatestMultiModel(timestampBefore: Date): Observable<ChartModelWrapper<any>> {
    return this.httpClient.get<ChartModelWrapper<any>>(HttpService.weatherPiUrl + `/api/forecasts/multimodel/temperature?timestampBefore=${timestampBefore.getTime()}`)
  }

  getLatestMultiModelWindSpeed(timestampBefore: Date): Observable<ChartModelWrapper<any>> {
    return this.httpClient.get<ChartModelWrapper<any>>(HttpService.weatherPiUrl + `/api/forecasts/multimodel/windspeed?timestampBefore=${timestampBefore.getTime()}`)
  }

  getLatestMultiModelWindDir(timestampBefore: Date): Observable<ChartModelWrapper<any>> {
    return this.httpClient.get<ChartModelWrapper<any>>(HttpService.weatherPiUrl + `/api/forecasts/multimodel/winddirection?timestampBefore=${timestampBefore.getTime()}`)
  }

  getLatestMultiModelPrecipitation(timestampBefore: Date): Observable<ChartModelWrapper<any>> {
    return this.httpClient.get<ChartModelWrapper<any>>(HttpService.weatherPiUrl + `/api/forecasts/multimodel/precipitation?timestampBefore=${timestampBefore.getTime()}`)
  }

  getLatestMultiModelCloudCover(timestampBefore: Date): Observable<ChartModelWrapper<any>> {
    return this.httpClient.get<ChartModelWrapper<any>>(HttpService.weatherPiUrl + `/api/forecasts/multimodel/cloudcover?timestampBefore=${timestampBefore.getTime()}`)
  }


  getLatestGfsEnsembleTemperature(): Observable<ChartModel> {
    return this.httpClient.get<ChartModel>(HttpService.weatherPiUrl + '/api/forecasts/ensemble/temperature')
  }

  getLatestGfsEnsemblePrecipitation(): Observable<ChartModel> {
    return this.httpClient.get<ChartModel>(HttpService.weatherPiUrl + '/api/forecasts/ensemble/precipitation')
  }

  getLatestGfsEnsembleWindSpeed(): Observable<ChartModel> {
    return this.httpClient.get<ChartModel>(HttpService.weatherPiUrl + '/api/forecasts/ensemble/windspeed')
  }

  getLatestGfsEnsembleGusts(): Observable<ChartModel> {
    return this.httpClient.get<ChartModel>(HttpService.weatherPiUrl + '/api/forecasts/ensemble/gusts')
  }

  getLatestGfsEnsembleWindDirection(): Observable<ChartModel> {
    return this.httpClient.get<ChartModel>(HttpService.weatherPiUrl + '/api/forecasts/ensemble/winddirection')
  }

  getLatestVisibility(): Observable<any> {
    return this.httpClient.get<any>(HttpService.weatherPiUrl + '/api/forecasts/cloudcover')
  }

  getTemperatureAnalysisData(startDate: string, timeStart: string, endDate: string, timeEnd: string): Observable<ChartModel> {
    const params = new HttpParams()
      .append('startDate', startDate)
      .append('timeStart', timeStart)
      .append('endDate', endDate)
      .append('timeEnd', timeEnd);

    return this.httpClient.get<ChartModel>(HttpService.weatherPiUrl + `/api/weather/analysis/temperature`, {params})
  }
}
