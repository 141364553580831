import {Component, Input, OnInit} from '@angular/core';
import {ChartModel} from "../../model/chart-model.model";
import {Observable} from "rxjs";

@Component({
  selector: 'app-grouped-charts',
  templateUrl: './grouped-charts.component.html',
  styleUrls: ['./grouped-charts.component.scss']
})
export class GroupedChartsComponent implements OnInit {

  @Input()
  data: Observable<ChartModel>[]

  constructor() { }

  ngOnInit(): void {
  }

}
