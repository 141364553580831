import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {DailyExtremes} from "../../model/daily-extremes.model";

@Component({
  selector: 'app-extremes',
  templateUrl: './extremes.component.html',
  styleUrls: ['./extremes.component.scss']
})
export class ExtremesComponent implements OnInit {

  dataSource: MatTableDataSource<DailyExtremes>;
  _dailyExtremes: DailyExtremes[];

  displayedColumns = ['date', 'temp', 'hum', 'maxWind', 'pressure', 'maxSolar', 'rain']
  _loaded = false;

  constructor() { }

  ngOnInit() {}

  @Input()
  set dailyExtremes(dailyExtremes: DailyExtremes[]) {
    this._dailyExtremes = dailyExtremes;
    this.dataSource = new MatTableDataSource<DailyExtremes>(this._dailyExtremes);
  }

  textColor(value: number, key: string): string {
    if (!value) {
      return 'rgb(0, 0, 0)';
    }

    const vals: number[] = this.dataSource.data.map(d => d[key].value)
    let color;
    if (value === Math.min(...vals)) {
      color = 'rgb(91,91,255)';
    } else if (value === Math.max(...vals)) {
      color = 'rgba(255,57,57,0.74)';
    } else color = '';
    return color;
  }
}
