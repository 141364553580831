import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {InfoComponent} from "./info/info.component";
import {WeatherComponent} from "./weather/weather.component";
import {ChartsComponent} from "./charts/charts.component";
import {RadarComponent} from "./radar/radar.component";
import {ExtremesComponent} from "./statistics/extremes/extremes.component";
import {WeathercamComponent} from "./weathercam/weathercam.component";
import {StatisticsComponent} from "./statistics/statistics.component";
import {SensorsComponent} from "./sensors/sensors.component";
import {MultiModelComponent} from "./multi-model/multi-model.component";
import {EnsemblesComponent} from "./ensembles/ensembles.component";
import {GalleryComponent} from "./gallery/gallery.component";
import {AnalysisComponent} from "./analysis/analysis.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  },
  {
    path: 'dashboard',
    component: WeatherComponent,
  },
  {
    path: 'grafieken',
    component: ChartsComponent,
  },
  {
    path: 'radar',
    component: RadarComponent
  },
  {
    path: 'info',
    component: InfoComponent,
  },
  {
    path: 'statistieken',
    component: StatisticsComponent
  },
  {
    path: 'weermodellen',
    component: MultiModelComponent
  },
  {
    path: 'pluim',
    component: EnsemblesComponent
  },
  {
    path: 'extremen',
    component: ExtremesComponent
  },
  {
    path: 'sensoren',
    component: SensorsComponent
  },
  {
    path: 'weercam',
    component: WeathercamComponent
  },
  {
    path: 'galerij',
    component: GalleryComponent
  },
  {
    path: 'analyse',
    component: AnalysisComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
